'use client';
import {removeCookie} from "@/api/auth";
import {IconLogout} from "@tabler/icons-react";

export const LogoutButton = () => {
    const handleLogout = async () => {
        await removeCookie().then(() => {
            window.location.reload();
        });
    };

    return (
        <button className="focus:outline-none" type="button" onClick={handleLogout}>
            <IconLogout className="text-default-500 hover:opacity-80 cursor-pointer" />
        </button>
    );
}